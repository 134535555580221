export const LoadingBar = () => {
  return(
    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <div style={{width: '50%', textAlign: 'center'}}>
        <span>Идет формирование данных</span>
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      </div>
    </div>
  )
}
